<template>
  <div>
    <!--Breadcrumb-->
    <section>

      <Breadcrumb
        title="Giriş"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: false, url: '', text: 'Giriş' },
        ]"
      />
    </section>
    <!--/Breadcrumb-->

    <!--Login-Section-->
    <section class="sptb" style="min-height: 590px">
      <div class="container customerpage">
        <div class="row">
          <div class="col-lg-4 d-block mx-auto">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-md-12">
                <div class="card mb-0">
                  <div class="card-header">
                    <h3 class="card-title">Giriş</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label class="form-label text-dark">E-Posta</label>
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="E-Posta"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label text-dark">Parola</label>
                      <input
                        type="password"
                        class="form-control"
                        name="password"
                        @keyup="enterKey"
                        id="exampleInputPassword1"
                        placeholder="Parola"
                      />
                    </div>
                    <div class="form-group">
                      <label class="custom-control form-checkbox">
                        <router-link
                          to="forgot-password"
                          class="float-end small text-dark mt-1"
                          >Parolamı unuttum</router-link
                        >
                      </label>
                    </div>
                    <div class="form-footer mt-2">
                      <button
                        @click="login"
                        id="loginButton"
                        class="btn btn-primary btn-block"
                      >
                        Giriş
                      </button>
                    </div>
                    <div class="text-center mt-3 text-dark">
                      Henüz üye değil misin?
                      <router-link to="/register">Hemen üye ol!</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/Login-Section-->
  </div>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb.vue";
export default {
  metaInfo() {
    return {
      title: "Giriş Yap - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Giriş Yap - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "Giriş Yap - Terapi Vitrini",
      "Giriş Yap - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
      "",
      "",
      "",
    ]);
    if (this.$store.state.isLogin) {
      this.$router.push("/home");
    }
  },
  data() {
    return {
      pageGet: [],
    };
  },
  methods: {
    enterKey(event){
      if(event.key==='Enter'){
        this.login()
      }
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    login() {
      document.getElementById("loginButton").disabled = true;
      document.getElementById("loginButton").innerHTML =
        "Bilgiler kontrol ediliyor..";
      let email = document.querySelector("input[name=email]").value;
      let password = document.querySelector("input[name=password]").value;
      if (email == null || email == "") {
        /**EMAİL BOŞ */
        this.$vToastify.warning("Email girilmedi", "Uyarı!");
      } else if (password == null || password == "") {
        /**PAROLA BOŞ */
        this.$vToastify.warning("Parola girilmedi", "Uyarı!");
      } else {
        let loginInfo = {
          email: email,
          password: password,
        };
        this.$store
          .dispatch("login", loginInfo)
          .then((value) => {
            if (value.message == "ERRxUMNA") {
              document.getElementById("loginButton").disabled = false;
              document.getElementById("loginButton").innerHTML = "Giriş Yap";
              this.$vToastify.error(
                "Kullanıcı Onaylı Değil, Mail adresinizi kontrol ediniz",
                "Uyarı!"
              );
            } else if (value.message == "ERRxUMPE") {
              document.getElementById("loginButton").disabled = false;
              document.getElementById("loginButton").innerHTML = "Giriş Yap";
              this.$vToastify.error(
                "Kullanıcı Mail yada Şifre Hatalı",
                "Uyarı!"
              );
            } else if (value.message == "ERRxURM") {
              document.getElementById("loginButton").disabled = false;
              document.getElementById("loginButton").innerHTML = "Giriş Yap";
              this.$vToastify.error("Kullanıcı silinmiş!", "HATA");
            } else {
              this.$vToastify.success(
                "Giriş başarılı, yönlendiriliyorsunuz",
                "Başarılı"
              );
              setTimeout(() => {
                this.$store.state.isLogin = true;
                this.$router.push("/profile");
              }, 2000);
            }
          })
          .catch((e) => {
            document.getElementById("loginButton").disabled = false;
            document.getElementById("loginButton").innerHTML = "Giriş Yap";
            this.$vToastify.error("Yetkisiz Giriş", "Uyarı!");
          });
      }
    },
  },
  components: { Breadcrumb },
  mounted() {
    this.backToTop();
  },
};
</script>